export default function TableViewIcon(props) {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M25.75 10V18"
        stroke="#0AA699"
        strokeWidth="3"
        strokeLinecap="round"
      />
      <path
        d="M2.25 10L2.25 18"
        stroke="#0AA699"
        strokeWidth="3"
        strokeLinecap="round"
      />
      <path
        d="M10 2.25L18 2.25"
        stroke="#0AA699"
        strokeWidth="3"
        strokeLinecap="round"
      />
      <path
        d="M10 25.75L18 25.75"
        stroke="#0AA699"
        strokeWidth="3"
        strokeLinecap="round"
      />
      <rect
        x="3.75"
        y="3.19141"
        width="21"
        height="21"
        rx="1.5"
        fill="#0AA699"
        stroke="#E7F6F5"
      />
    </svg>
  );
}
