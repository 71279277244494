export default function OrderViewIcon(props) {
    return (
        <svg
            width="22"
            height="28"
            viewBox="0 0 22 28"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M1.25879 1.5C1.25879 1.22386 1.48265 1 1.75879 1H20.2415C20.5177 1 20.7415 1.22386 20.7415 1.5L20.7415 26.7073L17.6054 25.2106C17.4188 25.1215 17.1994 25.1348 17.0249 25.2459C17.0249 25.2459 17.0249 25.2459 17.0249 25.2459L14.3944 26.9198L11.2875 25.2251C11.1084 25.1274 10.8919 25.1274 10.7129 25.2251L7.60593 26.9198L4.97545 25.2459C4.80095 25.1348 4.58155 25.1215 4.39489 25.2106L1.25879 26.7073L1.25879 1.5ZM7.39875 27.0328C7.39892 27.0327 7.3991 27.0326 7.39928 27.0325L7.39875 27.0328Z"
                fill="#1877F2"
                stroke="#E8F2FF"
            />
            <mask id="path-2-inside-1_3880_62794" fill="white">
                <path d="M14.4901 8.52993C14.7662 8.52993 14.9926 8.30553 14.9653 8.03075C14.9237 7.61263 14.83 7.20233 14.6863 6.8121C14.4858 6.26748 14.1919 5.77262 13.8214 5.35579C13.4509 4.93895 13.011 4.6083 12.5269 4.38271C12.0428 4.15712 11.5239 4.04102 10.9999 4.04102C10.4759 4.04102 9.95706 4.15712 9.47295 4.38271C8.98884 4.6083 8.54897 4.93895 8.17845 5.35579C7.80793 5.77262 7.51402 6.26748 7.3135 6.8121C7.16982 7.20233 7.0761 7.61263 7.03451 8.03075C7.00718 8.30553 7.23362 8.52993 7.50977 8.52993L10.9999 8.52993H14.4901Z" />
            </mask>
            <path
                d="M14.4901 8.52993C14.7662 8.52993 14.9926 8.30553 14.9653 8.03075C14.9237 7.61263 14.83 7.20233 14.6863 6.8121C14.4858 6.26748 14.1919 5.77262 13.8214 5.35579C13.4509 4.93895 13.011 4.6083 12.5269 4.38271C12.0428 4.15712 11.5239 4.04102 10.9999 4.04102C10.4759 4.04102 9.95706 4.15712 9.47295 4.38271C8.98884 4.6083 8.54897 4.93895 8.17845 5.35579C7.80793 5.77262 7.51402 6.26748 7.3135 6.8121C7.16982 7.20233 7.0761 7.61263 7.03451 8.03075C7.00718 8.30553 7.23362 8.52993 7.50977 8.52993L10.9999 8.52993H14.4901Z"
                fill="#1877F2"
                stroke="#E8F2FF"
                strokeWidth="2"
                mask="url(#path-2-inside-1_3880_62794)"
            />
            <path
                d="M6.34473 8.53125L15.6551 8.53125"
                stroke="#E8F2FF"
                strokeLinecap="round"
            />
            <ellipse
                cx="11.0005"
                cy="4.04112"
                rx="0.665025"
                ry="0.748153"
                fill="#E8F2FF"
            />
            <path
                d="M3.55176 13.0703H6.34486"
                stroke="#E8F2FF"
                strokeLinecap="round"
            />
            <path
                d="M8.20703 13.0703H18.4484"
                stroke="#E8F2FF"
                strokeLinecap="round"
            />
            <path
                d="M3.55176 15.8633H6.34486"
                stroke="#E8F2FF"
                strokeLinecap="round"
            />
            <path
                d="M8.20703 15.8633H18.4484"
                stroke="#E8F2FF"
                strokeLinecap="round"
            />
            <path
                d="M3.55176 18.6562H18.4483"
                stroke="#E8F2FF"
                strokeLinecap="round"
            />
            <path
                d="M3.55176 21.4473H8.20693"
                stroke="#E8F2FF"
                strokeLinecap="round"
            />
        </svg>
    );
}
