import React, { useEffect, useState } from 'react';
import { Select } from 'antd';

import './index.scss';
import ChevIcon from 'assets/icons/ChevIcon';
import TableViewIcon from 'assets/icons/swithView/TableViewIcon';
import OrderViewIcon from 'assets/icons/swithView/OrderViewIcon';
import KitchenViewIcon from 'assets/icons/swithView/KitchenViewIcon';
import { useLocation } from 'react-router';
import { useHistory } from "react-router-dom";

const urlMerchant = process.env.REACT_APP_MERCHANT_URL;

const { Option } = Select;

const optionsView = [
    {
        value: `${urlMerchant}merchant-table-view`,
        icon: <TableViewIcon style={{ marginRight: 16 }} />,
        label: 'Table View',
    },
    {
        value: `${urlMerchant}merchant-order-list?redirect=true`,
        icon: <OrderViewIcon style={{ marginRight: 16 }} />,
        label: 'Order View',
    },
    {
        value: `/home`,
        icon: <KitchenViewIcon style={{ marginRight: 16 }} />,
        label: 'Kitchen View',
        style: { borderBottom: 'none' },
    },
];

const SwitchView = () => {
    const location = useLocation();
    const history = useHistory();

    const [themeColor, setThemeColor] = useState('#FFA600');
    const [value, setValue] = useState('');

    const handleChangeView = (value) => {
        if (!value?.includes('home')) {
            window.location.href = value;
        } else {
            history.push(value);
        }
    };

    const handleChangeTheme = (colorValue) => {
        setThemeColor(colorValue);
        const labelEle = document.querySelectorAll('.ant-select-selection-item')[0];
        labelEle.style.color = colorValue;
        const selectorEle = document.querySelectorAll('.switchViewCls .ant-select-selector')[0];
        selectorEle.style.setProperty('border', `1px solid ${colorValue}`, 'important');
    };

    useEffect(() => {
        if (location.pathname === '/home') {
            handleChangeTheme('#FFA600');
        }
        setValue(location.pathname);
    }, [location.pathname]);

    return (
        <Select
            defaultValue={'/'}
            value={value}
            style={{
                width: 232,
                marginLeft: 32,
                color: themeColor,
            }}
            onChange={handleChangeView}
            suffixIcon={<ChevIcon color={themeColor} />}
            className="switchViewCls"
            id="switchViewId"
            dropdownStyle={{
                padding: 0,
            }}
        >
            {optionsView.map((option) => (
                <Option
                    key={option.value}
                    value={option.value}
                    style={{
                        borderBottom: `1px solid ${themeColor}`,
                        borderRadius: '0',
                        height: 64,
                        paddingTop: 20,
                        outline: 'none',
                        fontSize: 20,
                        fontWeight: 700,
                        ...option.style
                    }}
                >
                    {option.icon}
                    {option.label}
                </Option>
            ))}
        </Select>
    );
};

export default SwitchView;
