export default function ChevIcon(props) {
    return (
        <svg
            width="29"
            height="15"
            viewBox="0 0 29 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M15.0863 14.5082C14.7042 14.8574 14.1189 14.8574 13.7368 14.5082L0.724425 2.61308C0.05079 1.99729 0.486453 0.875 1.39913 0.875L27.424 0.875003C28.3366 0.875003 28.7723 1.99729 28.0987 2.61309L15.0863 14.5082Z"
                fill={props?.color || '#0AA699'}
            />
        </svg>
    );
}
