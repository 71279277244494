export default function KitchenViewIcon(props) {
    return (
        <svg
            width="28"
            height="28"
            viewBox="0 0 28 28"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M21.9449 13.3396V27.2431H6.05524V13.3396C5.21712 13.3396 4.39096 13.1407 3.64468 12.7592C2.8984 12.3778 2.2533 11.8246 1.7624 11.1453C1.2715 10.466 0.948812 9.67991 0.820868 8.85161C0.692924 8.02331 0.763373 7.17647 1.02642 6.3807C1.28947 5.58493 1.73762 4.86294 2.33402 4.27408C2.93042 3.68522 3.65805 3.2463 4.4571 2.99339C5.25616 2.74049 6.10383 2.68082 6.93043 2.81929C7.75704 2.95776 8.53898 3.29042 9.21199 3.78992C9.64095 2.88291 10.3187 2.11638 11.1664 1.57959C12.014 1.0428 12.9967 0.757812 14.0001 0.757812C15.0034 0.757812 15.9861 1.0428 16.8338 1.57959C17.6815 2.11638 18.3592 2.88291 18.7882 3.78992C19.4612 3.29042 20.2431 2.95776 21.0697 2.81929C21.8963 2.68082 22.744 2.74049 23.5431 2.99339C24.3421 3.2463 25.0697 3.68522 25.6661 4.27408C26.2626 4.86294 26.7107 5.58493 26.9737 6.3807C27.2368 7.17647 27.3072 8.02331 27.1793 8.85161C27.0514 9.67991 26.7287 10.466 26.2378 11.1453C25.7469 11.8246 25.1018 12.3778 24.3555 12.7592C23.6092 13.1407 22.7831 13.3396 21.9449 13.3396Z"
                fill="#FFA600"
                stroke="#FFECAE"
                strokeWidth="1.5"
            />
        </svg>
    );
}
